<template>

    <div>
  
      <b-row>
        <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="margin-bottom:10px">

          <b-alert
                variant="secondary"
                show
                
                >
                <div class="alert-body">
                        
                  <p style="font-size:14px;text-align: center;font-weight: bold;">Paso {{ step }} de {{ maxStep }} - {{ nameStep }}</p>


                </div>
                </b-alert>

         
              
          
        </b-col>

        </b-row>

        <b-form autocomplete="off" @submit.prevent="onSubmitGeneral" v-if="showStep1">

         <b-row>
       

             <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="margin-top:10px">

            <p style="font-size:12px;text-align: left;font-weight: bold;margin:0px">Categoria (*):</p>

                <v-select
                v-model="category"
                placeholder="Escoja una opción"
                :options="categorias"
                :required="!categoryId"
                label="name"
                @input="setSelected"
            >
                <template #search="{attributes, events}">
                <input
                    class="vs__search"
                    :required="!categoryId"
                    v-bind="attributes"
                    v-on="events"
                >
                </template>

            </v-select>
            </b-col>

            
            <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="margin-top:10px">

          <p style="font-size:12px;text-align: left;font-weight: bold;margin:0px">Cobertura (*):</p>

          <b-form-select
                        required
                            v-model="cobertura"
                            :options="optionsCobertura"
                            block
                            size="md"
                            />

          </b-col>

          <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="margin-top:10px">
         
         <p style="font-size:12px;text-align: left;font-weight: bold;margin:0px">Título de la publicación de la suscripción (*):</p>

         <b-form-textarea
                          v-model="title"
                          placeholder="Ingrese el titulo de la suscripción que va a publicar."
                          rows="8"
                          required
                          no-resize
                          :state="title.length <= maxChar2"
                          class="char-textarea"
                          :class="title.length >= maxChar2 ? 'text-danger' : ''"
                        />
                        <small
                          class="textarea-counter-value float-left"
                          :class="title.length >= maxChar2 ? 'bg-danger' : ''"
                        >
                          <span class="char-count">{{ title.length }}</span> / {{ maxChar2 }}
                        </small>
                           
                

  
             </b-col>


             <b-col cols="12" sm="12" md="12" lg="6" xl="6" style="margin-top:10px">
                <p style="font-size:12px;text-align: left;font-weight: bold;margin:0px">Precio de la suscripción (*):</p>

                <b-input-group
                append="USD"
                >
                <cleave
                        v-model="form.price"
                        class="form-control"
                        :raw="false"
                        required
                        :options="number"
                        placeholder="$0.00"
                        />
                </b-input-group>

          
                </b-col>

         

                
               

                    <b-col cols="12" sm="12" md="12" lg="6" xl="6" style="margin-top:10px">
                    <p style="font-size:12px;text-align: left;font-weight: bold;margin:0px">Recurrencia del pago (*):</p>

                    <b-form-select
                        required
                            v-model="recurrencia"
                            :options="options2"
                            block
                            size="md"
                            />
          
                </b-col>

             

         
                    
      
  
          <b-col sm="12" md="12" lg="12" xl="12" style="margin-top:25px;justify-content: space-between;display: flex;" >
            

                <b-button   @click="cancelar" variant="outline-secondary" >Cancelar  </b-button>

                <b-button   class="animacion_button"  type="submit"    variant="primary" >  Siguiente   <feather-icon  icon="ArrowRightCircleIcon" size="15" style="margin-left: 5px;"/>       



                </b-button>



                </b-col>
      
      </b-row>

                  
           </b-form>   
           
           
           <b-form autocomplete="off" @submit.prevent="onSubmitComisiones" v-if="showStep2">

              <b-row>

                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <b-card>

                    <p style="font-size:12px;margin:0px; text-align:left">En esta sección deberá configurar si desea dar comisiones por las ventas que realizen la red de promotores de EQCoop o dar una comisión directa a un usuario que logre concretar una venta compartiendo su publicación. Ambos sistemas de comisiones funcionan por separado, por lo tanto, puede configurar ambos sistemas al mismo tiempo si lo desea.</p>

                  </b-card>
                </b-col>

                <b-col cols="12" sm="12" md="12" lg="12" xl="12">

                  <p style="margin:0px; font-weight: bold; text-align: center;">Comisión por ventas realizadas por la red de promotores</p>

               
                </b-col>

                <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="margin-top:10px">

                    <b-alert
                    variant="primary"
                    show

                    >
                    <div class="alert-body">
                            
                      <p style="font-size:12px;text-align: center;"><b>Nota: </b>La suma de todos los porcentajes no puede sobrepasar el 50%</p>


                    </div>
                    </b-alert>

                    </b-col>



                    <b-col cols="12" sm="12" md="12" lg="6" xl="4" style="margin-top:10px">
                        <p style="font-size:12px;text-align: left;font-weight: bold;margin:0px">Comisión 1er nivel <feather-icon
                                        icon="HelpCircleIcon"
                                        size="16"
                                          v-b-popover.hover.bottom.html="'Este valor corresponde al porcentaje que le desea dar al promotor de primer nivel del usuario que realizó la compra'"
                                        title="Porcentaje de comisión para promotor de primer nivel"
                                        style="margin-right: 5px;cursor:pointer;"
                                        />:</p>

                    <b-input-group
                    append="%"
                    >
                    <cleave
                            v-model="form.porcentaje1"
                            class="form-control"
                            :raw="false"
                            required
                            :options="porcentaje"
                            placeholder="Ingrese el porcentaje de comisión"
                            @blur="onBlurMount1"
                            />
                    </b-input-group>



                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="6" xl="4" style="margin-top:10px">
                        <p style="font-size:12px;text-align: left;font-weight: bold;margin:0px">Comisión 2do nivel <feather-icon
                                        icon="HelpCircleIcon"
                                        size="16"
                                          v-b-popover.hover.bottom.html="'Este valor corresponde al porcentaje que le desea dar al promotor de segundo nivel del usuario que realizó la compra'"
                                        title="Porcentaje de comisión para promotor de segundo nivel"
                                        style="margin-right: 5px;cursor:pointer;"
                                        />:</p>

                    <b-input-group
                    append="%"
                    >
                    <cleave
                            v-model="form.porcentaje2"
                            class="form-control"
                            :raw="false"
                            required
                            :options="porcentaje"
                            placeholder="Ingrese el porcentaje de comisión"
                            @blur="onBlurMount2"
                            />
                    </b-input-group>



                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="4" style="margin-top:10px">
                        <p style="font-size:12px;text-align: left;font-weight: bold;margin:0px">Comisión 3er nivel <feather-icon
                                        icon="HelpCircleIcon"
                                        size="16"
                                          v-b-popover.hover.bottom.html="'Este valor corresponde al porcentaje que le desea dar al promotor de tercer nivel del usuario que realizó la compra'"
                                        title="Porcentaje de comisión para promotor de tercer nivel"
                                        style="margin-right: 5px;cursor:pointer;"
                                        />:</p>

                    <b-input-group
                    append="%"
                    >
                    <cleave
                            v-model="form.porcentaje3"
                            class="form-control"
                            :raw="false"
                            required
                            :options="porcentaje"
                            placeholder="Ingrese el porcentaje de comisión"
                            @blur="onBlurMount3"
                            />
                    </b-input-group>



                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                      <hr>

                      <p style="margin:0px; font-weight: bold; text-align: center;">Comisión directa por ventas recomendadas por un usuario </p>


                      </b-col>

                      <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="margin-top:10px">

                        <b-alert
                        variant="primary"
                        show

                        >
                        <div class="alert-body">
                                
                          <p style="font-size:12px;text-align: center;"><b>Nota: </b>El porcentaje de esta comisión no puede sobrepasar el 50%</p>


                        </div>
                        </b-alert>

                        </b-col>

                        <b-col cols="12" sm="12" md="3" lg="3" xl="3">

                        </b-col>

                        <b-col cols="12" sm="12" md="6" lg="6" xl="6" style="margin-top:10px">
                        <p style="font-size:12px;text-align: left;font-weight: bold;margin:0px">Porcentaje de comisión <feather-icon
                                        icon="HelpCircleIcon"
                                        size="16"
                                          v-b-popover.hover.bottom.html="'Este valor corresponde al porcentaje que le desea dar a un usuario que compartió su publicación y que logró concretar una venta'"
                                        title="Porcentaje de comisión directa"
                                        style="margin-right: 5px;cursor:pointer;"
                                        />:</p>

                            <b-input-group
                            append="%"
                            >
                            <cleave
                                    v-model="form.comisionDirecta"
                                    class="form-control"
                                    :raw="false"
                                    required
                                    :options="porcentaje"
                                    placeholder="Ingrese el porcentaje de comisión"
                                    @blur="onBlurMount4"
                                    />
                            </b-input-group>



                            </b-col>

                            <b-col cols="12" sm="12" md="3" lg="3" xl="3">
                          
                        </b-col>


                <b-col sm="12" md="12" lg="12" xl="12" style="margin-top:10px;justify-content: space-between;display: flex;" >
            

            <b-button   @click="atras1" variant="outline-secondary" >Atras  </b-button>

            <b-button   class="animacion_button"  type="submit"    variant="primary" >  Siguiente   <feather-icon  icon="ArrowRightCircleIcon" size="15" style="margin-left: 5px;"/>       



            </b-button>



            </b-col>


                </b-row>


                </b-form>

           <b-form autocomplete="off" @submit.prevent="onSubmitDescription" v-else-if="showStep3">

             <b-row>

             

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="margin-bottom:5px">

                      <p style="font-size:12px;text-align: left;font-weight: bold;margin:0px">Descripción de la suscripción (*):</p>

                      <vue-editor v-model="description"  :editorToolbar="customToolbar"></vue-editor>

               
                           

                      </b-col>

                      <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="margin-bottom:15px">

                        <b-card style="margin:0px" >

                          <b-row>
                                              <b-col cols="1" sm="1" md="1" lg="1" xl="1" style="display:flex; padding:0px">

                                                <feather-icon
                                                    icon="AlertCircleIcon"
                                                    size="21"
                                             style="margin:auto"
                                                    /> 

                                              </b-col>
                                              <b-col cols="11" sm="11" md="11" lg="11" xl="11">

                                                <p style="line-height: 16px;  text-align:justify;font-size:12px; margin:0px">Incluye todos los detalles que puedan ayudar a las personas a entender la suscripción que vendes. Si incluyes una descripción detallada, es posible que lo vendas más rápido.</p>

                                              </b-col>

                                            </b-row>
                          </b-card>


                        </b-col>
                     

                      <b-col sm="12" md="12" lg="12" xl="12" style="justify-content: space-between;display: flex;" >
            

                        <b-button   @click="atras2" variant="outline-secondary" > <feather-icon

                            icon="ArrowLeftCircleIcon"
                            size="13"
                            style= "margin-right:10px"
                            /> Atras  </b-button>

                        <b-button   class="animacion_button"  type="submit"    variant="primary" >  Siguiente   <feather-icon  icon="ArrowRightCircleIcon" size="15" style="margin-left: 5px;"/>       



                        </b-button>



                        </b-col>
                        
              </b-row>
            </b-form>

            <b-form autocomplete="off" @submit.prevent="onSubmit" v-else-if="showStep4">

                <b-row>

       
                  <b-col cols="3" sm="3" md="3" lg="3" xl="3" style="display:flex">

                    <div style="margin:auto">

                      <b-img style="width: 100%; height: 48px;" :src="srcImagen1" class="img"></b-img>

                      <b-button block variant="outline-secondary" :disabled="isActive" v-if="hasImagen1" size="sm" @click="eliminar('imagen1')" >

                                <feather-icon

                          icon="TrashIcon"
                          size="12"
                          style= "margin-right:2px"
                          />

                  

                      </b-button>

                    </div>

                 

                    </b-col>
                    <b-col cols="9" sm="9" md="9" lg="9" xl="9">

                      <p style="font-size:12px;text-align: left;font-weight: bold;margin:0px">Imagen principal de la publicación (*): <feather-icon
                                    icon="HelpCircleIcon"
                                    size="16"
                                      v-b-popover.hover.bottom.html="'Esta imagen será la foto principal de la publicación de tu suscripción. Debe pesar menos de 3mb y tener un tamaño de 1200px x 720px'"
                                    title="Imagen principal de la publicación"
                                    style="margin-right: 5px;cursor:pointer;"
                                    /></p>
                      <b-form-file
                        v-model="imagen1"
                        required
                        :disabled="isActive"
                        accept="image/jpeg, image/jpg"
                        placeholder="Buscar imagen o suéltelo aquí..."
                        drop-placeholder="Suelte la imagen aqui..."
                        @change="previewImage1"
                      />
        
                      <p style="margin: 0;font-size: 10px;display:flex">{{ uploadValue1.toFixed()+"%" }}
                        <progress
                          style="width:100%"
                          :value="uploadValue1"
                          max="100"
                        />  </p>

                      

                      </b-col>



                      <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="margin-bottom:10px;padding:0px" v-if="postVisible >= 2">

                        <b-row>
                     
                          <b-col cols="3" sm="3" md="3" lg="3" xl="3" style="display:flex">


                            <div style="margin:auto">

                              <b-img style="width: 100%; height: 48px;" :src="srcImagen2" class="img"></b-img>

                              <b-button block variant="outline-secondary" :disabled="isActive" v-if="hasImagen2" size="sm" @click="eliminar('imagen2')" >

                                        <feather-icon

                                  icon="TrashIcon"
                                  size="12"
                                  style= "margin-right:2px"
                                  />

                                                

                                </b-button>
                              </div>


                              </b-col>
                              <b-col cols="9" sm="9" md="9" lg="9" xl="9">
                                <p style="font-size:12px;text-align: left;font-weight: bold;margin:0px">Imagen #2 (Opcional):</p>

                                <b-form-file
                                  v-model="imagen2"
                                  
                                  :disabled="isActive"
                                  accept="image/jpeg, image/jpg"
                                  placeholder="Buscar imagen o suéltelo aquí..."
                                  drop-placeholder="Suelte la imagen aqui..."
                                  @change="previewImage2"
                                />

                                <p style="margin: 0;font-size: 10px;display:flex">{{ uploadValue2.toFixed()+"%" }}
                                  <progress
                                    style="width:100%"
                                    :value="uploadValue2"
                                    max="100"
                                  />  </p>


                                </b-col>
                        </b-row>
                     
                          </b-col>
                          <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="margin-bottom:10px;padding:0px" v-if="postVisible >= 3">
                            <b-row>
                    
                              <b-col cols="3" sm="3" md="3" lg="3" xl="3" style="display:flex">

                                <div style="margin:auto">
                                  <b-img style="width: 100%; height: 48px;" :src="srcImagen3" class="img"></b-img>

                              <b-button block variant="outline-secondary" :disabled="isActive" v-if="hasImagen3" size="sm" @click="eliminar('imagen3')" >

                                        <feather-icon

                                  icon="TrashIcon"
                                  size="12"
                                  style= "margin-right:2px"
                                  />

                  

                                 </b-button>
                                
                                </div>

                        

                                  </b-col>
                                  <b-col cols="9" sm="9" md="9" lg="9" xl="9">
                                    <p style="font-size:12px;text-align: left;font-weight: bold;margin:0px">Imagen #3 (Opcional):</p>
                                    <b-form-file
                                        v-model="imagen3"
                                        
                                        :disabled="isActive"
                                        accept="image/jpeg, image/jpg"
                                        placeholder="Buscar imagen o suéltelo aquí..."
                                        drop-placeholder="Suelte la imagen aqui..."
                                        @change="previewImage3"
                                      />

                                      <p style="margin: 0;font-size: 10px;display:flex">{{ uploadValue3.toFixed()+"%" }}
                                        <progress
                                          style="width:100%"
                                          :value="uploadValue3"
                                          max="100"
                                        />  </p>


                                    </b-col>
                            </b-row>
                        

                         


                          </b-col>

                          <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="margin-bottom:0px;padding:0px" v-if="postVisible >= 4">

                            <b-row>
                         
                              <b-col cols="3" sm="3" md="3" lg="3" xl="3" style="display:flex">

                                <div style="margin:auto">
                                
                                  <b-img style="width: 100%; height: 48px;" :src="srcImagen4" class="img"></b-img>

                                  <b-button block variant="outline-secondary" :disabled="isActive" v-if="hasImagen4" size="sm" @click="eliminar('imagen4')" >

                                            <feather-icon

                                      icon="TrashIcon"
                                      size="12"
                                      style= "margin-right:2px"
                                      />

                                                    

                                                        </b-button>
                                
                              </div>


                                </b-col>
                                <b-col cols="9" sm="9" md="9" lg="9" xl="9">
                                  <p style="font-size:12px;text-align: left;font-weight: bold;margin:0px">Imagen #4 (Opcional):</p>

                                  <b-form-file
                                    v-model="imagen4"
                                    
                                    :disabled="isActive"
                                    accept="image/jpeg, image/jpg"
                                    placeholder="Buscar imagen o suéltelo aquí..."
                                    drop-placeholder="Suelte la imagen aqui..."
                                    @change="previewImage4"
                                  />

                                  <p style="margin: 0;font-size: 10px;display:flex">{{ uploadValue4.toFixed()+"%" }}
                                    <progress
                                      style="width:100%"
                                      :value="uploadValue4"
                                      max="100"
                                    />  </p>


                                  </b-col>
                          </b-row>

                          
                          


                            </b-col>

                            <b-col v-if="postVisible < 4" cols="12" sm="12" md="12" lg="12" xl="12" style="margin-bottom:0px" align="center">
                              <p style="text-align: center; font-weight: bold;font-size:12px; margin-bottom:5px">¿Deseas agregar otra imagen a la publicación?</p>
                            <b-button
                                
                                                                
                                                                  variant="outline-secondary"
                                                                  
                                                                  size="sm"
                                                                  @click="postVisible += 1" 
                                                            
                                                                >

                                                                <feather-icon

                                              icon="PlusIcon"
                                              size="12"
                                              style= "margin-right:2px"
                                              />

                                                             Agregar otra imagen

                                                                </b-button>
                              </b-col>
                      
                      <b-col
                                    sm="12"
                                    md="12"
                                    lg="12"
                                    xl="12"
                                  >

                                  <hr>
                                    <p
                                      class="textLabel"
                                      style="text-align: center;"
                                    >
                                      Ingrese su código PIN (*): <feather-icon
                                    icon="HelpCircleIcon"
                                    size="16"
                                      v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                    title="Código PIN de seguridad"
                                    style="margin-right: 5px;cursor:pointer;"
                                    /> </p>

                                    <PincodeInput
                                      v-model="pin"
                                      placeholder="-"
                                      :length="6"
                                      :autofocus="false"
                                      :secure="true"
                                      :readonly="isActive"
                                      required
                                    />

                                  </b-col>

                      <b-col sm="12" md="12" lg="12" xl="12" style="justify-content: space-between;display: flex;" >
            

                        <b-button  :disabled="isActive"   @click="atras3" variant="outline-secondary" > <feather-icon

                            icon="ArrowLeftCircleIcon"
                            size="13"
                           
                            style= "margin-right:10px"
                            /> Atras  </b-button>

                        <b-button id="btn_registrar"  class="animacion_button"  type="submit" :disabled="isActive"    variant="primary" >  Registrar suscripción     



                        </b-button>



                        </b-col>

                </b-row>

             </b-form>      
  
            </div>
      
         
      
      </template>
      
      <script>
      import {
        BButton, BRow,BAlert, VBPopover,BFormTextarea,BImg, BCol,BForm,BFormFile, BCard,BFormInput, BInputGroup, BInputGroupPrepend, BInputGroupAppend, BFormGroup, BFormSelect
      } from 'bootstrap-vue'
      import 'firebase/compat/storage'
      import Cleave from 'vue-cleave-component'
      import vSelect from 'vue-select'
      import 'vue-select/dist/vue-select.css'

      import { VueEditor } from "vue2-editor";
      import PincodeInput from 'vue-pincode-input'
      export default {
        components: {
          
            BInputGroup, BInputGroupPrepend, BInputGroupAppend, BFormGroup, BFormSelect,
            BFormInput,BForm,BFormTextarea,
          BButton,BAlert,BFormFile,BImg,
          BRow,
          BCol,
          PincodeInput,
          BCard,
          Cleave,
          vSelect,
          VueEditor
        },
        directives: {
          'b-popover': VBPopover,
        },
        props: ['userId','tokenAuth','idLocal'],
        data() {
      
      
          return {
            customToolbar: [
                ["bold", "italic", "underline"],
                [{ 'size': ['small', false, 'large', 'huge'] }],
                [{ list: "ordered" }, { list: "bullet" }],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],
                ['link']
            ],
            maxChar: 1500,
            maxChar2: 500,
            srcImagen1:"/img/image.svg",
            srcImagen2:"/img/image.svg",
            srcImagen3:"/img/image.svg",
            srcImagen4:"/img/image.svg",
            postVisible:1,
            description:"",
            showStep1:true,
            showStep2:false,
            showStep3:false,
            showStep4:false,
            recurrencia:"",
            cobertura:"Nacional (Ecuador)",
            imagen1:[],
            imagen2:[],
            imagen3:[],
            imagen4:[],
            hasImagen1:false,
            hasImagen2:false,
            hasImagen3:false,
            hasImagen4:false,
            isActive:false,
            urlImagen1:"",
            urlImagen2:"",
            urlImagen3:"",
            urlImagen4:"",
            pin:"",
            uploadValue1: 0,
            uploadValue2:0,
            uploadValue3:0,
            uploadValue4:0,
            imageData1: null,
            category:"",
            categoryId:"",
            nameStep:"Información de la suscripción",
            form: {
                price: "",
                comisionDirecta:0,
                porcentaje1:0,
                porcentaje2:0,
                porcentaje3:0
              },
            step:1,
            number: {
              prefix: '$ ',
              numeral: true,
              numeralPositiveOnly: true,
              noImmediatePrefix: true,
              rawValueTrimPrefix: true,
              numeralIntegerScale: 9,
              numeralDecimalScale: 2
                },
                porcentaje: {
              prefix: '',
              numeral: true,
              numeralPositiveOnly: true,
              noImmediatePrefix: true,
              rawValueTrimPrefix: true,
              numeralIntegerScale: 3,
              numeralDecimalScale: 0
                },
            maxStep:4,
            title:"",
                options2: [
                { value: '', text: 'Escoja una opción' },
                { value: 'Pago unico', text: 'Pago único' },
                { value: 'Diario', text: 'Diario' },
                { value: 'Mensual', text: 'Mensual' },
                { value: 'Trimestral', text: 'Trimestral' },
                { value: 'Semestral', text: 'Semestral' },
                { value: 'Anual', text: 'Anual' },
                ],
                categorias: [],
                optionsCobertura:[
                { value: 'Nacional (Ecuador)', text: 'Cobertura Nacional (Ecuador)' },
                { value: 'Internacional', text: 'Cobertura Internacional' },
                { value: 'Nacional e Internacional', text: 'Cobertura Nacional e Internacional' },

                ]
          }
        },
        computed: {
      
        },
        watch: {
      
        },
        mounted() {

            this.loadCategorias();
      
        },
        methods: {

          

          onBlurMount4(){

            if(this.form.comisionDirecta === ""){

              this.form.comisionDirecta=0;

              }

              if(Number(this.form.comisionDirecta) > Number("50")){

                this.$toast.error('El porcentaje de comisión directa no pueden ser mayor al 50%', {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
                })

                this.form.comisionDirecta=0;

                }


          },

            onBlurMount1(){

                if(this.form.porcentaje1 === ""){

                    this.form.porcentaje1=0;

                }

                if(this.form.porcentaje2 === ""){

                        this.form.porcentaje2=0;

                        }

                        if(this.form.porcentaje3 === ""){

                        this.form.porcentaje3=0;

                        }

                let total= Number(this.form.porcentaje1)+Number(this.form.porcentaje2)+Number(this.form.porcentaje3);


               
                if(Number(total) > Number("50")){

                    this.$toast.error('La suma de los porcentajes no pueden ser mayor al 50%', {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                })

                    this.form.porcentaje1=0;

                }

            },

            onBlurMount2(){

                

                if(this.form.porcentaje1 === ""){

                        this.form.porcentaje1=0;

                        }

                        if(this.form.porcentaje2 === ""){

                            this.form.porcentaje2=0;

                            }

                            if(this.form.porcentaje3 === ""){

                            this.form.porcentaje3=0;

                            }

                        let total= Number(this.form.porcentaje1)+Number(this.form.porcentaje2)+Number(this.form.porcentaje3);



                        if(Number(total) > Number("50")){

                        this.$toast.error('La suma de los porcentajes no pueden ser mayor al 50%', {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                        })

                        this.form.porcentaje2=0;

                        }

                },


                onBlurMount3(){

                                        

                    if(this.form.porcentaje1 === ""){

                    this.form.porcentaje1=0;

                    }

                    if(this.form.porcentaje2 === ""){

                        this.form.porcentaje2=0;

                        }

                        if(this.form.porcentaje3 === ""){

                        this.form.porcentaje3=0;

                        }

                    let total= Number(this.form.porcentaje1)+Number(this.form.porcentaje2)+Number(this.form.porcentaje3);



                    if(Number(total) > Number("50")){

                    this.$toast.error('La suma de los porcentajes no pueden ser mayor al 50%', {
                    position: 'top-right',
                    timeout: 3010,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.4,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: 'button',
                    icon: true,
                    rtl: false,
                    })

                    this.form.porcentaje3=0;

                    }

                },

          previewImage1(event) {

           
              const FileSizeBanner = event.target.files[0].size / 1024 / 1024

              

              let foto=event.target.files[0];
        
              if (FileSizeBanner > 3) {
                this.imagen1 = [];
                this.hasImagen1=false;
        
                this.$toast.error('La imagen debe pesar menos de 3mb', {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                })
              }else{

                
              

              let imgwidth=0;
              let imgheight=0;
 

                      var _URL = window.URL || window.webkitURL;
                    
                      var img = new Image();
                    
                      img.onload = () => {
                          // here you got the width and height
                          imgwidth = img.width;
                        imgheight = img.height;

                        if(Number(imgwidth) === Number("1200") && Number(imgheight) === Number("720")){
                          this.srcImagen1=_URL.createObjectURL(foto);
                          this.hasImagen1=true;
                        }else{

                          
                          this.eliminar("imagen1");
                          this.$toast.error('Su imagen debe tener un tamaño de 1200px x 720px', {
                            position: 'top-right',
                            timeout: 3010,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.4,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: 'button',
                            icon: true,
                            rtl: false,
                          })

                        }



                };
                img.src = _URL.createObjectURL(foto);



  
              }
            },

            eliminar(value){
              switch (value) {
                case "imagen1":
                  this.srcImagen1="/img/image.svg";
                this.hasImagen1=false;
                this.imagen1 = [];
                this.urlImagen1="";
                this.uploadValue1=0;
                break
                case "imagen2":
                this.hasImagen2=false;
                this.srcImagen2="/img/image.svg";
                this.imagen2 = [];
                this.urlImagen2="";
                this.uploadValue2=0;
                break
                case "imagen3":
                this.hasImagen3=false;
                this.srcImagen3="/img/image.svg";
                this.imagen3 = [];
                this.urlImagen3="";
                this.uploadValue3=0;
                break
                case "imagen4":
                this.hasImagen4=false;
                this.imagen4 = [];
                this.srcImagen4="/img/image.svg";
                this.urlImagen4="";
                this.uploadValue4=0;
                break
        
           
                }
            },

            
          previewImage2(event) {
              const FileSizeBanner = event.target.files[0].size / 1024 / 1024
              let foto=event.target.files[0];
              if (FileSizeBanner > 3) {
                this.imagen2 = [];
                this.hasImagen2=false;
                this.$toast.error('La imagen debe pesar menos de 3mb', {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                })
              }else{

                                  
                                

                  let imgwidth=0;
                  let imgheight=0;


                          var _URL = window.URL || window.webkitURL;
                        
                          var img = new Image();
                        
                          img.onload = () => {
                              // here you got the width and height
                              imgwidth = img.width;
                            imgheight = img.height;

                            if(Number(imgwidth) === Number("1200") && Number(imgheight) === Number("720")){
                              this.srcImagen2=_URL.createObjectURL(foto);
                              this.hasImagen2=true;
                              }else{

                                
                                this.eliminar("imagen2");
                                this.$toast.error('Su imagen debe tener un tamaño de 1200px x 720px', {
                                  position: 'top-right',
                                  timeout: 3010,
                                  closeOnClick: true,
                                  pauseOnFocusLoss: false,
                                  pauseOnHover: true,
                                  draggable: true,
                                  draggablePercent: 0.4,
                                  showCloseButtonOnHover: false,
                                  hideProgressBar: true,
                                  closeButton: 'button',
                                  icon: true,
                                  rtl: false,
                                })

                              }
                              

                        


                    };
                    img.src = _URL.createObjectURL(foto);




                  }
            },


            
          previewImage3(event) {
              const FileSizeBanner = event.target.files[0].size / 1024 / 1024
              let foto=event.target.files[0];
              if (FileSizeBanner > 3) {
                this.imagen3 = [];
                this.hasImagen3=false;
                this.$toast.error('La imagen debe pesar menos de 3mb', {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                })
              }else{

                                                  
                                                

                let imgwidth=0;
                let imgheight=0;


                        var _URL = window.URL || window.webkitURL;
                      
                        var img = new Image();
                      
                        img.onload = () => {
                            // here you got the width and height
                            imgwidth = img.width;
                          imgheight = img.height;

                          if(Number(imgwidth) === Number("1200") && Number(imgheight) === Number("720")){
                            this.srcImagen3=_URL.createObjectURL(foto);
                            this.hasImagen3=true;
                              }else{

                                
                                this.eliminar("imagen3");
                                this.$toast.error('Su imagen debe tener un tamaño de 1200px x 720px', {
                                  position: 'top-right',
                                  timeout: 3010,
                                  closeOnClick: true,
                                  pauseOnFocusLoss: false,
                                  pauseOnHover: true,
                                  draggable: true,
                                  draggablePercent: 0.4,
                                  showCloseButtonOnHover: false,
                                  hideProgressBar: true,
                                  closeButton: 'button',
                                  icon: true,
                                  rtl: false,
                                })

                              }




                  };
                  img.src = _URL.createObjectURL(foto);




                }
            },


            
          previewImage4(event) {
              const FileSizeBanner = event.target.files[0].size / 1024 / 1024
              let foto=event.target.files[0];
              if (FileSizeBanner > 3) {
                this.imagen4 = [];
                this.hasImagen4=false;
                this.$toast.error('La imagen debe pesar menos de 3mb', {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                })
              }else{

                                  
                                                  

                  let imgwidth=0;
                  let imgheight=0;


                          var _URL = window.URL || window.webkitURL;
                        
                          var img = new Image();
                        
                          img.onload = () => {
                              // here you got the width and height
                              imgwidth = img.width;
                            imgheight = img.height;

                            
                          if(Number(imgwidth) === Number("1200") && Number(imgheight) === Number("720")){
                            this.srcImagen4=_URL.createObjectURL(foto);
                              this.hasImagen4=true;
                              }else{

                                
                                this.eliminar("imagen4");
                                this.$toast.error('Su imagen debe tener un tamaño de 1200px x 720px', {
                                  position: 'top-right',
                                  timeout: 3010,
                                  closeOnClick: true,
                                  pauseOnFocusLoss: false,
                                  pauseOnHover: true,
                                  draggable: true,
                                  draggablePercent: 0.4,
                                  showCloseButtonOnHover: false,
                                  hideProgressBar: true,
                                  closeButton: 'button',
                                  icon: true,
                                  rtl: false,
                                })

                              }

                         


                    };
                    img.src = _URL.createObjectURL(foto);




                  }
            },

        loadCategorias(){



          this.$https.post('/locals/getCategories/', { type: "Servicio"}).then(response => {

         
          if (response.data.code == 200) {
          
      
              this.categorias= response.data.categorias;
     

          } else {

         
            this.loadCategorias();
              
          
          }
          }).catch(error => {
          this.loadCategorias();
          })

},

            setSelected(value) {

      this.categoryId = value.id
      this.category = value.name
    },
    onSubmitDescription(event){
      event.preventDefault();

  

      if(Number(this.description.length) <= Number("0")){

            this.$toast.error("La descripción de la suscripción es obligatoria", {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            })

          }else{
            
            this.showStep1=false;
            this.showStep3=false;
             this.showStep4=true;
             this.showStep2=false;
             this.step=4;
             this.nameStep="Imágenes de la suscripción";

          }



    },

    onSubmitComisiones(event){
             event.preventDefault();

             this.showStep1=false;
                      this.showStep3=true;
                      this.showStep4=false;
                      this.showStep2=false;
                      this.step=3;
                      this.nameStep="Descripción de la suscripción";


    },
         onSubmitGeneral(event){
             event.preventDefault();


      
             
            if(Number(this.title.length) > Number(this.maxChar2)){

                this.$toast.error("El texto del título sobrepasa el límite permitido de "+this.maxChar2+" caracteres", {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                })

                }else{


                      this.showStep1=false;
                      this.showStep3=false;
                      this.showStep4=false;
                      this.showStep2=true;
                      this.step=2;
                      this.nameStep="Comisiones por ventas";

                }


            },
            atras1(){
              
              this.showStep1=true;
              this.showStep3=false;
              this.showStep4=false;
              this.showStep2=false;
              this.step=1;
              this.nameStep="Información de la suscripción";
            },
            atras2(){
              
              this.showStep1=false;
              this.showStep3=false;
              this.showStep4=false;
              this.showStep2=true;
              this.step=2;
              this.nameStep="Comisión de ventas";


            },

            atras3(){

              this.showStep1=false;
              this.showStep3=true;
              this.showStep4=false;
              this.showStep2=false;
              this.step=3;
              this.nameStep="Descripción de la suscripción";

            },
            cancelar(){
                this.$eventBus.$emit('closemodalSuscripcion');
            },

           async uploadImg(imagen,type) {
              return new Promise((resolve, reject) => {
                document.getElementById('btn_registrar').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Subiendo imagen'
                switch (type) {
                          case "imagen1":
                          this.uploadValue1 = 0
                          break
                          case "imagen2":
                          this.uploadValue2 = 0
                          break
                          case "imagen3":

                          this.uploadValue3 = 0   
                
                          break

                          case "imagen4":
                          this.uploadValue4 = 0
                       
                          break

                        }


                const d1 = new Date();

                let nameFile= this.userId+"_"+d1.getTime()+"_"+imagen.name;
                let dir= 'suscripciones/'+this.userId;

                const storageRef = this.$appFirebase.storage().ref(dir).child(`${nameFile}`).put(imagen);

                storageRef.on('state_changed', snapshot => {

                  switch (type) {
                          case "imagen1":
                          this.uploadValue1 = (snapshot.bytesTransferred / snapshot.totalBytes) * 100       
                          break
                          case "imagen2":
                          this.uploadValue2 = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                          break
                          case "imagen3":

                          this.uploadValue3 = (snapshot.bytesTransferred / snapshot.totalBytes) * 100      
                
                          break

                          case "imagen4":
                          this.uploadValue4 = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                       
                          break

                        }
                     
              
                    
                    }, error => {

         

                      this.eliminar(type);

                      reject(false);
                    },
                    async () => {

                      switch (type) {
                          case "imagen1":
                          this.uploadValue1 = 100
                          this.urlImagen1=await storageRef.snapshot.ref.getDownloadURL()
                          break
                          case "imagen2":
                          this.uploadValue2 = 100
                          this.urlImagen2=await storageRef.snapshot.ref.getDownloadURL()
                          break
                          case "imagen3":

                          this.uploadValue3 = 100   
                          this.urlImagen3=await storageRef.snapshot.ref.getDownloadURL()
                          break

                          case "imagen4":
                          this.uploadValue4 = 100
                          this.urlImagen4=await storageRef.snapshot.ref.getDownloadURL()
                          break

                        }

                      resolve(true);

                    })
                

              });
            },
  
   async onSubmit(event) {
         event.preventDefault();

        this.isActive = true;
        let respuesta1=true;
        let respuesta2=true;
        let respuesta3=true;
        let respuesta4=true;

        if(this.urlImagen1 != "" || this.hasImagen1 === true){
          respuesta1=  await this.uploadImg(this.imagen1, "imagen1");
        }

        if(this.urlImagen2 != "" || this.hasImagen2 === true){
          respuesta2=  await this.uploadImg(this.imagen2, "imagen2");
        }


        if(this.urlImagen3 != "" || this.hasImagen3 === true){
          respuesta3=  await this.uploadImg(this.imagen3, "imagen3");
        }

        if(this.urlImagen4 != "" || this.hasImagen4 === true){
          respuesta4=  await this.uploadImg(this.imagen4, "imagen4");
        }

        

         if(respuesta1 && respuesta2 && respuesta3 && respuesta4){

          const user_json = {
            userId: this.userId, idLocal: this.idLocal, pin: this.pin
           }





         const user_string = JSON.stringify(user_json)
      
         const dataEncripted1 = this.$encryptBackoffice.encrypt(user_string);
     
         document.getElementById('btn_registrar').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Registrando'
 
         let precio= this.form.price;

         let porcentaje1= this.form.porcentaje1;
         let porcentaje2= this.form.porcentaje2;
         let porcentaje3= this.form.porcentaje3;

         let comisionDirecta=  this.form.comisionDirecta;

            let array= precio.split("$ ");

            let precioFinal= array[1];
      
       this.$https.post('/locals/saveSuscripciones/', { tokenAuth: this.tokenAuth,cobertura: this.cobertura, dataEncripted1: dataEncripted1,  urlImagen3: this.urlImagen3, urlImagen4: this.urlImagen4,  urlImagen1: this.urlImagen1, urlImagen2: this.urlImagen2, description: this.description, title: this.title, idCategoria:this.categoryId, category: this.category,porcentaje1: porcentaje1,porcentaje2: porcentaje2, porcentaje3: porcentaje3,comisionDirecta:comisionDirecta, price: precioFinal, recurrencia: this.recurrencia }).then(response => {
         document.getElementById('btn_registrar').innerHTML = 'Registrar suscripción';
         this.isActive=false;
 
   
     
       if (response.data.code == 200) {
 
        this.$eventBus.$emit('closemodalSuscripcion');
 
         this.$toast.success(response.data.message, {
           position: 'top-right',
           timeout: 3010,
           closeOnClick: true,
           pauseOnFocusLoss: false,
           pauseOnHover: true,
           draggable: true,
           draggablePercent: 0.4,
           showCloseButtonOnHover: false,
           hideProgressBar: true,
           closeButton: 'button',
           icon: true,
           rtl: false,
         });
 
      
 
         
 
         this.$eventBus.$emit('reiniciarListadoSuscripciones');
  
 
       } else {
 
        this.$toast.error(response.data.message, {
           position: 'top-right',
           timeout: 3010,
           closeOnClick: true,
           pauseOnFocusLoss: false,
           pauseOnHover: true,
           draggable: true,
           draggablePercent: 0.4,
           showCloseButtonOnHover: false,
           hideProgressBar: true,
           closeButton: 'button',
           icon: true,
           rtl: false,
         })
 
       if (response.data.code == 401) {
 
      
 
         localStorage.removeItem('userData')
 
 
         this.$router.push({ name: 'auth-login' })
       } else {
 
         if(response.data.code == 404){

           this.$eventBus.$emit('closemodalSuscripcion');
         }else{


          if(response.data.code == 503){

            this.pin="";

          }else{
            if(response.data.code == 405){

                this.$eventBus.$emit('reiniciarNegocio');

            }


          }

         }
         
       }
       }
       }).catch(error => {
         this.$toast.error("Ocurrio un error " + error, {
           position: 'top-right',
           timeout: 3010,
           closeOnClick: true,
           pauseOnFocusLoss: false,
           pauseOnHover: true,
           draggable: true,
           draggablePercent: 0.4,
           showCloseButtonOnHover: false,
           hideProgressBar: true,
           closeButton: 'button',
           icon: true,
           rtl: false,
         })
       })
 


      
         }else{
          this.active = false
                      document.getElementById('btn_registrar').innerHTML = 'Registrar suscripción'
                      this.$toast.error("Ocurrió un error inesperado con una de sus imágenes", {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                      })
         }

 
     },
      
         
      
        },
      }
      </script>
      
      <style lang="scss">
      
      @import "~vue2-editor/dist/vue2-editor.css";
      
      </style>
      