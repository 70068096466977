<template>

    <div>


        <b-card v-if="!hasPlan">

            <b-row>

                               
                <b-col
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                    align="center"
                    >

                 
                    <p style="text-align:center; font-weight: bold;font-size:16px">Funcionalidad no disponible</p>

                    <svg class="icons_colors4" style="width:100px; height:100px"  viewBox="0 -32 576 576" xmlns="http://www.w3.org/2000/svg"><path d="M485.5.0 576 160H474.9L405.7.0h79.8zm-128 0 69.2 160H149.3L218.5.0h139zm-267 0h79.8l-69.2 160H0L90.5.0zM0 192h100.7l123 251.7c1.5 3.1-2.7 5.9-5 3.3L0 192zm148.2.0h279.6l-137 318.2c-1 2.4-4.5 2.4-5.5.0L148.2 192zm204.1 251.7 123-251.7H576L357.3 446.9c-2.3 2.7-6.5-.1-5-3.2z"/></svg>

                    
                    <b-alert
                        variant="primary"
                        show
                      >
                        <div class="alert-body">
                     

                                
                                  <p style="text-align: center;font-size:14px; margin-bottom:0px"> Necesitas tener un plan diferente del Free para utilizar esta funcionalidad.</p>

                     
                        </div>
                      </b-alert>

                      
                 
                      <b-button 
                            :to="{ name: 'marketplaceparam', params: { id: id }}"
                                variant="primary"
                                size="md"
                                
                                style="margin-top:10px; margin-bottom:0px"
                                class="animacion_button" 
                                >
                                <feather-icon

                                icon="AwardIcon"
                                size="18"
                                style= "margin-right:2px"
                                />
                        
                                Suscribirme al plan negocios EQCoop

                                </b-button>

                </b-col>

             
            </b-row>


        </b-card>

        <b-row v-else>

            <b-modal
                ref="modalOpciones"
                                centered
                                hide-footer
                                hideHeaderClose
                                hideHeader
                                size="sm"
                            
                    >
                        <div class="d-block text-center">

                            <p style="font-weight: bold; text-align:center; font-size:14px">Seleccione que publicación desea crear</p>

                            <b-row>
                                <b-col cols="12" sm="12" md="6" lg="4" xl="4">

                                    <b-card class="card_opciones" @click="openModalCreateSuscripcion" >

                                        <b-row>
                                        <b-col cols="2" sm="2" md="12" lg="12" xl="12" align="center" style="display: flex;" >

                                        <b-avatar
                                                size="40"
                                                variant="light-primary"
                                                style="margin:auto;">

                                                <span class="d-flex align-items-center">

                                                    <feather-icon
                                                                icon="StarIcon"
                                                                size="21"

                                                            
                                                                />

                                            

                                                </span>

                                                </b-avatar> 

                                            </b-col>

                                            <b-col cols="10" sm="10" md="12" lg="12" xl="12" style="display: flex;"  >
                                                <p style=" font-size:12px; text-align: center;    margin: auto; font-weight: bold;" >Suscripción</p>  
                                        

                                        
                                        
                                            </b-col>
                                        </b-row>

                                        </b-card>

                                </b-col>

                                <b-col cols="12" sm="12" md="6" lg="4" xl="4">

                                        <b-card class="card_opciones" @click="openModalCreateProducto" >

                                            <b-row>
                                            <b-col cols="2" sm="2" md="12" lg="12" xl="12" align="center" style="display: flex;" >

                                            <b-avatar
                                                    size="40"
                                                    variant="light-primary"
                                                    style="margin:auto;">

                                                    <span class="d-flex align-items-center">

                                                        <feather-icon
                                                                    icon="ShoppingBagIcon"
                                                                    size="21"

                                                                
                                                                    />

                                                

                                                    </span>

                                                    </b-avatar> 

                                                </b-col>

                                                <b-col cols="10" sm="10" md="12" lg="12" xl="12" style="display: flex;"  >
                                                    <p style=" font-size:12px; text-align: center;    margin: auto; font-weight: bold;" >Producto</p>  
                                            

                                            
                                            
                                                </b-col>
                                            </b-row>

                                            </b-card>

                                        </b-col>

                                 <b-col cols="12" sm="12" md="6" lg="4" xl="4">

                                    <b-card class="card_opciones" @click="openModalCreateServicio" >

                                        <b-row>
                                        <b-col cols="2" sm="2" md="12" lg="12" xl="12" align="center" style="display: flex;" >

                                        <b-avatar
                                                size="40"
                                                variant="light-primary"
                                                style="margin:auto;">

                                                <span class="d-flex align-items-center">

                                                    <feather-icon
                                                                icon="ToolIcon"
                                                                size="21"

                                                            
                                                                />

                                            

                                                </span>

                                                </b-avatar> 

                                            </b-col>

                                            <b-col cols="10" sm="10" md="12" lg="12" xl="12" style="display: flex;"  >
                                                <p style=" font-size:12px; text-align: center;    margin: auto; font-weight: bold;" >Servicio</p>  
                                        

                                        
                                        
                                            </b-col>
                                        </b-row>

                                        </b-card>

                                    </b-col>
                            </b-row>


                </div>


                </b-modal>


            <modal-create-suscripcion v-if="openModalSuscripcion" :key="componentKeyModalSuscripcion"  :user-id="userId" :token-auth="tokenAuth"   :id-local="idLocal"/>
  

            <b-col cols="12" sm="12" md="12" lg="12" xl="6">

                <b-card class="card_opciones" @click="openModal" >

                        <b-row>
                        <b-col cols="2" sm="2" md="2" lg="2" xl="2" align="center" style="display: flex;" >

                        <b-avatar
                                size="40"
                                variant="light-primary"
                                style="margin:auto;">

                                <span class="d-flex align-items-center">

                                    <feather-icon
                                                icon="StarIcon"
                                                size="21"

                                              
                                                />

                               

                                </span>

                                </b-avatar> 

                            </b-col>

                            <b-col cols="10" sm="10" md="10" lg="10" xl="10"   >
                                <p style=" font-size:14px; text-align: center;margin:0px; font-weight: bold;" >¿Desea vender algo?<br><span style="font-weight: 400;font-style: italic; font-size:12px">Publícalo ahora en tu empresa</span></p>  
                           

                          
                         
                            </b-col>
                        </b-row>

                        </b-card>

            </b-col>

            <b-col cols="12" sm="12" md="12" lg="12" xl="6">

                <b-card class="card_opciones" @click="clonProducto" >

                        <b-row>
                        <b-col cols="2" sm="2" md="2" lg="2" xl="2" align="center" style="display: flex;" >

                        <b-avatar
                                size="40"
                                variant="light-primary"
                                style="margin:auto;">

                                <span class="d-flex align-items-center">

                                    <feather-icon
                                                icon="RepeatIcon"
                                                size="21"

                                            
                                                />

                            

                                </span>

                                </b-avatar> 

                            </b-col>

                            <b-col cols="10" sm="10" md="10" lg="10" xl="10"   >
                                <p style=" font-size:14px; text-align: center;margin:0px; font-weight: bold;" >Importar suscripción<br><span style="font-weight: 400;font-style: italic; font-size:12px">Clona suscripciones de tus otras empresas</span></p>  
                            
                          
                        
                            </b-col>
                        </b-row>

                        </b-card>


            </b-col>

            <b-col cols="12" sm="12" md="12" lg="12" xl="12" >
                <listado-suscripciones v-if="showListado" :key="componentKeyListadoSuscripciones" :user-id="userId" :token-auth="tokenAuth" :id-local="idLocal" />

            </b-col>

        </b-row>
        
    </div>

    
         
      
      </template>
      
      <script>
      import {
        BButton, BRow, BCol, BCard, BAvatar, BAlert
      } from 'bootstrap-vue'
      
      import ListadoSuscripciones from './ListadoSuscripciones.vue'
      import ModalCreateSuscripcion from './ModalCreateSuscripcion.vue'
      export default {
        components: {
          BButton,
          BAvatar,
          ModalCreateSuscripcion,
          BRow,
          BCol,
          BCard,
          BAlert,
          ListadoSuscripciones
        },
        props: ['userId','tokenAuth', 'idLocal','hasPlan'],
        data() {
      
      
          return {
       
            showListado:false,
            openModalSuscripcion:false,
            componentKeyListadoSuscripciones:0,
            componentKeyModalSuscripcion:0,
            id:"MKu7aBE8ARFnEXItWqWwBMdn1q0aKGSTBDZNqs4uLdKgB99idSkTPTOHZWn2IzCt",
          }
        },
        computed: {
      
        },
        watch: {
      
        },
        mounted() {

            this.$eventBus.$on('reiniciarModalSuscripcion', () => {
                this.componentKeyModalSuscripcion += 1
                this.openModalSuscripcion = false;
                });


            this.$eventBus.$on('reiniciarListadoSuscripciones', () => {
                this.componentKeyListadoSuscripciones += 1

                })

            this.showListado=true;

    
        },
        methods: {

            openModalCreateSuscripcion(){
                this.$refs.modalOpciones.hide()
                this.openModalSuscripcion=true;
            },

            openModalCreateProducto(){

                this.$toast.error("Funcionalidad no disponible por el momento", {
                          position: 'top-right',
                          timeout: 3010,
                          closeOnClick: true,
                          pauseOnFocusLoss: false,
                          pauseOnHover: true,
                          draggable: true,
                          draggablePercent: 0.4,
                          showCloseButtonOnHover: false,
                          hideProgressBar: true,
                          closeButton: 'button',
                          icon: true,
                          rtl: false,
                        })

            },
            openModalCreateServicio(){

                this.$toast.error("Funcionalidad no disponible por el momento", {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                        })

                },

            openModal(){
                this.$refs.modalOpciones.show()
      

            },clonProducto(){

            }, 

          
      
        },
      }
      </script>
      
      <style lang="scss">
      
      
      
      </style>
      