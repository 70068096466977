<template>

    <div>
     <b-modal
       ref="modalSuscripcion"
       centered
       size="md"
       hide-footer
       hideHeaderClose
                hideHeader
       @hidden="closemodalSuscripcion"
     >
       <div class="d-block text-center">

 
        <create-suscripcion  :user-id="userId" :token-auth="tokenAuth"   :id-local="idLocal"/>
  

         
 
       </div>
 
 
       </b-modal>
    </div>
       
          
       
       </template>
       
       <script>
       import {
         BButton, BRow, VBPopover, BCol, BCard,  BFormInput, BForm, BFormTextarea, BAlert, 
       } from 'bootstrap-vue'
       
    
       import CreateSuscripcion from './CreateSuscripcion.vue'
       export default {
         components: {
              BFormInput,
              BAlert,
              CreateSuscripcion,
              BFormTextarea,
              BForm,
              BButton,
              BRow,
              BCol,
              BCard,
              
         },
         directives: {
           'b-popover': VBPopover,
         },
         props: ['userId','tokenAuth','idLocal'],
         data() {
       
       
           return {
         
    
           }
         },
         computed: {
       
         },
         watch: {
       
         },
         mounted() {

 
         this.$refs.modalSuscripcion.show()
         this.$eventBus.$on('closemodalSuscripcion', () => {
          this.$refs.modalSuscripcion.hide()
          });
       
         },
         methods: {
        
 
 
             closemodalSuscripcion(){
 
    
 
                 this.$eventBus.$emit('reiniciarModalSuscripcion');
 
 
 
                 },

                 showProducto(){

                 },

                 showServicio(){

                  },

   
    
  
          
       
         },
       }
       </script>
       
       <style lang="scss">
       
       
       
       </style>
       